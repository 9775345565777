$mainBackgroundColor: #171629;
$darkPurpleText: #c9b7db;
$neonPurple: #8b50e3;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.show-more-butt {
  background-color: #404040;
  color: $darkPurpleText;
  border: none;
  text-align: center;
  width: 100%;
}

.description-box-home {
  margin-top: 10px;
  text-align: left;
  background-color: #404040;
  color: rgb(206, 205, 205);
  margin-bottom: 20px;
}

.home-page-div {
  background-color: $mainBackgroundColor;
  //height: 100vh;
  align-items: center;
  //justify-content: center;
  // padding-top: 80px;
  //font-size: 20px;
  //padding-top: 20px;
  //padding-bottom: 75px;
}

.home-page-container {
  //background-color: $mainBackgroundColor;
  // height: 100vh;
  align-items: center;
  //margin-top: auto;
  margin-top: 10%;
  margin-bottom: auto;
}

@media only screen and (max-width: $md) {
  .name {
    font-size: 65px;
  }

  .bottom-subtitle {
    font-size: 17px;
  }
  .top-subtitle {
    font-size: 17px;
  }
}

@media only screen and (min-width: $md) {
  .name {
    font-size: 70px;
  }

  .bottom-subtitle {
    font-size: 25px;
  }
  .top-subtitle {
    font-size: 25px;
  }
}

.bottom-subtitle {
  color: $darkPurpleText;

  font-family: lft-etica-mono;
  font-weight: 300px;
}

.name {
  text-align: "center";
  justify-content: "center";

  color: $darkPurpleText;
  font-weight: 500;
  font-family: lft-etica-mono;
  font-style: normal;
}

.top-subtitle {
  color: $darkPurpleText;
  //font-family: "Courier New", Courier, monospace;
  font-family: lft-etica-mono;
  font-weight: 300px;
  justify-content: "center";
}

.home-page {
  background-color: $mainBackgroundColor;
  height: 100vh;
}

.home-body {
  color: $darkPurpleText;
}

.button-row {
  margin-top: 70px;
  justify-content: center;
  //justify-content: "space-around";
}

.home-button {
  //justify-content: "space-between";
  color: $darkPurpleText;
  border: none;
  //border-color: $mai;
  font-family: "Courier New", Courier, monospace;
  background-color: $mainBackgroundColor;
  //width: 150px;
  font-size: 20px;
  font-weight: 400;
}

/* ABOUT ME */

.about-me-point {
  color: $darkPurpleText;
  text-align: left;
  padding: 0px 12px 15px 12px;
}
