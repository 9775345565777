$mainBackgroundColor: #171629;
$darkPurpleText: #c9b7db;
$neonPurple: #8b50e3;
$lightPurpleBackground: #38324f;

$xs: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$customXL: 1400px;

///SMALL DISPLAY
.small-project-card {
  background-color: #282646;
  //background-color: white;
  padding: 20px 20px 10px 20px;
  margin-bottom: 100px;
  // height: fit-content;
  .card-body {
    padding: 0px !important;
    text-align: left;
  }
}

.small-project-subtitle {
  text-align: left;
  padding: 7px 0px 10px 0px;
  color: $darkPurpleText;
  font-weight: 300;
  text-shadow: 1px 1px 1px #000000;
}

//.modal-content {
.modal-main-container {
  color: $darkPurpleText;
  font-family: "lft-etica-mono";
  padding: "20px";
}

.project-modal-header {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.project-modal-close-button {
  color: $darkPurpleText;
  background-color: $mainBackgroundColor;
  border: none;
  font-size: 20px;
}

.modal-body {
  padding: 0px 10px 5px 10px !important;
}

.project-modal-title {
  color: white;
  font-size: 25px;
}

.project-modal-subtitle {
  color: $darkPurpleText;
  font-size: 18px;
  padding: 0px 0px 10px 10px;
}

.project-description-list-div {
  background-color: $lightPurpleBackground;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 20px;
  font-weight: 400;
}

.btn-close {
  color: $neonPurple;
  //background-color: $mainBackgroundColor;
  background: none;
}

//PROJECT DISPLAY EXTRA:
.read-more-butt {
  background-color: transparent;
  color: $darkPurpleText;
  width: fit-content;
  border-bottom: solid 1px;
  font-size: 15px;
  //text-align: left;
}

.project-description {
  font-weight: 400;
  color: $darkPurpleText;
}

.project-main-tech-list {
  text-align: left;
  padding: 0;
}
.project-tech {
  color: $darkPurpleText;
  font-weight: 300;
  padding-top: 0px;
  display: inline;
  text-shadow: 1px 1px 1px #000000;
  white-space: nowrap;
}

//MAX: SM
@media only screen and (max-width: $xs) {
  .card.project-card {
    //min-height: 345px;
    // height: fit-content;
  }
}

@media only screen and (min-width: $xs) {
}

@media only screen and (min-width: $xs) and (max-width: $sm) {
  .card.project-card {
    // min-height: 320px;
    // height: fit-content;
  }
}

@media only screen and (max-width: $sm) {
  .read-more-butt {
    display: block;
  }
  .card.project-card {
    //min-height: 290px;
    background-color: #282646;
    margin-bottom: 100px;
  }

  .project-description {
    margin-top: 15px;
    text-align: left;
  }

  // .project-title {
  //   font-weight: 500;
  //   font-size: 20px !important;
  //   text-align: left;
  //   color: white;
  // }
  .project-subtitle {
    padding-bottom: 10px;
    color: $darkPurpleText;
    font-weight: 500;
  }

  .project-modal-title {
    //text-align: center;
    text-align: left;
  }
}

//MIN: SM
@media only screen and (min-width: $sm) {
  // .project-title {
  //   font-weight: 400;
  //   font-size: 20px !important;
  //   text-align: left;
  //   color: $darkPurpleText;
  // }
  .read-more-butt {
    display: none;
  }
  .project-subtitle {
    text-align: left;
    padding-bottom: 10px;
    color: $darkPurpleText;
    font-weight: 300;
    text-shadow: 1px 1px 1px #000000;
  }
}

//BETWEEN SM & MD
@media only screen and (min-width: $sm) and (max-width: $md) {
  .card.project-card {
    //min-height: 330px;
    // height: fit-content;
    background-color: #282646;
    margin-bottom: 150px;
  }
}

//BETWEEN MD & LG
@media only screen and (min-width: $md) and (max-width: $lg) {
  .card.project-card {
    height: fit-content;
    // min-height: 280px;
    background-color: #282646;
    margin-bottom: 150px;
  }

  /*
  .card.project-card:hover {
    transform: scale(1.1);
  }*/

  /*
  .card.project-card:hover {
    transform: scale(1.1);
  }*/

  /*
  think I can remove this because of the one below 
  .project-page-container {
    max-width: 80% !important;
  }*/
}

//BETWEEN SM & LG
@media only screen and (min-width: $sm) and (max-width: $lg) {
  .project-page-container {
    max-width: 80% !important;
  }
  .project-description {
    margin-top: 10px;
    text-align: left;
    background-color: #404040;
    color: rgb(206, 205, 205);
    padding: 20px;
  }

  .project-card-overlay {
    padding: 20px;
    // height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }
}

//MAX: LG
@media only screen and (max-width: $lg) {
  .project-title {
    font-weight: 500;
    font-size: 20px !important;
    text-align: left;
    color: white;
  }
  .project-description {
    margin-top: 10px;
    text-align: left;
    background-color: #404040;
    color: rgb(206, 205, 205);
    padding: 10px;
    font-size: 15px;
  }
  .project-image {
    display: none;
  }

  .project-main-tech-list {
    //display: none;
    overflow: scroll;
    font-size: 15px;
  }
}

//MIN: MD
@media only screen and (min-width: $lg) {
  .read-more-butt {
    display: none;
  }
  .project-page-container {
    max-width: 70% !important;
  }
  //make sure there is a class with this name in projectDisplay
  .project-card-img-overlay {
    padding: 0;
    background-color: rgb(23, 23, 41, 0.7);
  }
  //make sure there is a class with this name in projectDisplay
  .project-card-img-overlay:hover {
    padding: 0;
    background-color: rgb(23, 23, 41, 0);
  }

  .card.project-card {
    min-height: 400px;
    margin-bottom: 80px;
    border: none;
    color: white;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $mainBackgroundColor;
  }
  .project-image {
    display: block;
    margin-top: auto;
    margin-bottom: auto;

    opacity: 0.5;
  }

  .project-row {
    align-items: center;
  }
  .project-description {
    margin-top: 10px;
    background-color: #404040;
    color: rgb(206, 205, 205);
    padding: 30px;
    text-align: left;
  }

  .project-main-tech-list {
    display: block;
  }
}

//MIN: CUSTOM XL (1400PX)
@media only screen and (min-width: $customXL) {
  .project-image-simple {
    display: block;
  }
}

//MAX: CUSTOM XL (1400PX)
@media only screen and (max-width: $customXL) {
  .project-image-simple {
    display: none;
  }

  // .read-more-butt {
  //   margin-top: 10px;
  // }
}

.project-card {
}

.project-image {
}

.project-card-img-overlay {
}

.project-title {
}

.project-description {
}

// .read-more-button {
// }
