$mainBackgroundColor: #171629;
$darkPurpleText: #c9b7db;
$neonPurple: #8b50e3;

.contact-page {
  background-color: $mainBackgroundColor;
  height: 100vh;
}
.contact-card-row {
  justify-content: center;
}

.contact-card {
  width: 60%;
  padding: 20px;
  background-color: $darkPurpleText;
  height: 400px;
}

.contact-item {
  color: $neonPurple;
  justify-content: center;
  font-size: 25px;
  font-weight: 300;
  font-family: "Courier New", Courier, monospace;
  margin-top: 10px;
  margin-bottom: 50px;
}
